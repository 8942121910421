:root {
  --primary: #202947;
  --secondary: #5c67f7;
  --text-muted: #6e829f;
  --font-family-poppins: "Poppins", sans-serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-y: hidden;
}

/* General Css Start */
/* Font Weight Custom Class Start */
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* Font Weight Custom Class End */
/* Font Size Custom Class Start */
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.font-family-poppins {
  font-family: "Poppins", sans-serif;
}

.rem-5 {
  padding-bottom: 5rem !important;
}

/* Font Size Custom Class End */

.text-muted {
  color: var(--text-muted) !important;
}

.primary-text {
  color: var(--primary);
}

.secondary-button {
  padding: 10px;
  background-color: var(--secondary);
  color: #fff;
  text-align: center;
  border: 0px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family-poppins);
}

.back-btn {
  padding: 8px;
  background-color: rgba(92, 103, 247, 0.1);
  color: #fff;
  text-align: center;
  border: 0px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family-poppins);
  width: 30px;
  height: 30px;
}

.back-btn svg {
  font-size: 20px;
  color: var(--primary);
}

input.form-control,
select.form-select {
  padding: 9px 10px;
}

textarea.form-control,
input.form-control,
select.form-select,
input.form-control::placeholder {
  color: #a0a5a9;
  font-weight: 300;
  font-size: 16px;
}

.form-control:focus,
.css-t3ipsp-control {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--primary);
  outline: 0;
  box-shadow: none !important
}

.page-link:focus {
  box-shadow: none !important;
}

.top-70 {
  top: 70%;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

label {
  font-weight: 500;
}

.css-hlgwow {
  padding: 5px !important;
}

.css-19bb58m {
  color: var(--primary) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.formfile-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.formfile-image img {
  width: 100%;
  height: 100%;
}

.save-btn {
  padding: 10px 30px;
  border: 0px;
  border-radius: 5px;
  background-color: var(--secondary);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.cancel-btn {
  padding: 10px 30px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  background-color: transparent;
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
}

th {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family-poppins);
  text-wrap: nowrap;
}

td {
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-family-poppins);
  vertical-align: middle;
  max-width: 250px;
  width: auto;
  text-wrap: wrap;
}

td p {
  max-width: 250px;
  width: auto;
  text-wrap: wrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

.table-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 1px solid var(--primary);
  border-radius: 50%;
}

.switch-box .form-label.text-default {
  width: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.label-none label {
  display: none;
}

.single-error .error {
  margin: 0px !important;
}

/* General Css End*/

/* Login Pega Start */
.login {
  background-color: rgba(32, 41, 71, 0.8);
}

.login .card.custom-card {
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
  border: 0;
  position: relative;
  margin-block-end: 1.5rem;
  width: 100%;
}

/* Login Pega End */

/* Layout Page Start */
.sidebar {
  max-width: 250px;
  width: 100%;
  background-color: var(--primary);
  height: 100vh;
  overflow-y: auto;
  font-family: var(--font-family-poppins);
  transition: all 0.2s ease;
  overflow-x: hidden;
}

.sidebar-sm {
  max-width: 60px;
}

.category-list {
  list-style: none;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
}

.category-header {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #fff;
}

.icon {
  font-size: 20px;
}

.subcategory-list {
  list-style: none;
  /* padding-left: 20px; */
  margin: 0;
  color: #fff;
}

.subcategory-list li {
  margin: 5px 0;
  list-style-type: disc;
}

.subcategory-list li a {
  color: #b2b8c7;
  font-weight: 400;
  text-decoration: none;
  padding: 5px;
}

.subcategory-list li a:hover,
.subcategory-list li.active a {
  color: #fff;
}

.sidebar-toggle {
  width: 30px;
  height: 25px;
  cursor: pointer;
  position: relative;
}

.line {
  width: 100%;
  height: 4px;
  background-color: #333;
  border-radius: 2px;
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2) {
  top: 10px;
}

.line:nth-child(3) {
  top: 20px;
}

/* Animation when the toggle is open */
.sidebar-toggle.open .line:nth-child(1) {
  transform: rotate(45deg) translateY(10px);
}

.sidebar-toggle.open .line:nth-child(2) {
  opacity: 0;
}

.sidebar-toggle.open .line:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px);
}

.logo-xl {
  width: 150px;
}

.logo-sm {
  width: 25px;
  /* height: 65px; */
}

.side-top-bar {
  background-color: #fff;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 16px 20px;
  height: 56px;
  cursor: pointer;
}

.sidebar-toggel {
  font-size: 25px;
}

.category-link {
  color: #b2b8c7;
  font-weight: 400;
  text-decoration: none;
  padding: 5px;
}

.category-header svg {
  color: #b2b8c7;
}

.category-header.active svg {
  color: #fff;
}

.category-header.active {
  color: #fff;
  border-radius: 8px;
}

.category-header.active .category-link {
  color: #fff;
  font-weight: 600;
}

.category-link:hover {
  color: #fff;
}

.user-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--primary);
}

.user-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 0px;
  text-align: start;
}

.user-dropdown a {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 0px;
  text-align: start;
  font-family: "Poppins", sans-serif;
}

.side-icons {
  font-size: 20px;
  margin-bottom: 5px;
}

.sidebar-sm .category-link .mune-items {
  transition: all 0.2s ease;
}

@media only screen and (max-width: 2560px) and (min-width: 992px) {

  .sidebar-sm .category-header {
    justify-content: center;
  }

  .sidebar-sm .category-link .mune-items {
    display: none;
  }
}

.search-icon {
  width: 40px;
  height: 41px;
  background-color: rgba(92, 103, 247, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 68%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  right: 1px !important;
}

.search-icon svg {
  color: var(--secondary);
  font-size: 25px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input {
  border: var(--bs-border-width) solid var(--primary) !important;
}

.ck-content {
  height: 200px;
  overflow: auto;
}

.form-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--primary);
  outline: 0;
  box-shadow: none !important
}

.css-t3ipsp-control {
  border-color: var(--primary) !important;
}

/* Layout Page End */
/* Dashboard Page Start */

.bg-light-dark {
  background-color: #f9fafc;
}

.page-title {
  font-size: 25px;
  color: var(--primary);
  font-weight: 500;
  font-family: var(--font-family-poppins);
  margin-bottom: 0px;
}

.dashboard-cards h3 {
  font-size: 18px;
  /* color: var(--text-muted); */
  color: #000;
  font-weight: 400;
}

.dashboard-cards .cards-bottom {
  margin-top: 20px;
}

.dashboard-cards h5 {
  font-size: 15px;
  color: var(--text-muted);
  font-weight: 400;
  margin: 0px;
}

.dashboard-cards span {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.dashboard-cards svg {
  font-size: 20px;
  color: #fff;
}

.dashboard-cards .card {
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.05);
  border: 0;
  position: relative;
  margin-block-end: 1.5rem;
  width: 100%;
}

.dashboard-cards .card .card-body {
  padding: 20px;
}

.dashboard-cards .cards-icon-box {
  max-width: 40px;
  min-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dashboard-cards :nth-child(1) .card .cards-icon-box {
  background-color: #5c67f7;
}

.dashboard-cards :nth-child(2) .card .cards-icon-box {
  background-color: #e354d4;
}


.dashboard-cards :nth-child(3) .card .cards-icon-box {
  background-color: #ff8e6f;
}

.dashboard-cards :nth-child(4) .card .cards-icon-box {
  background-color: #ff5d9f;
}

.dashboard-cards :nth-child(5) .card .cards-icon-box {
  background-color: #6f42c1;
}

.dashboard-cards :nth-child(6) .card .cards-icon-box {
  background-color: #28a745;
}

/* Dashboard Page End */
/* Testimonials Page Start */
.table-card .card {
  border: 0px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 10px;
}

.table-card .card .card-body {
  border-radius: 20px;
}

.table-delete-btn:hover,
.table-delete-btn {
  width: 30px;
  height: 30px;
  padding: 0px;
  background-color: rgba(251, 66, 66, 0.2);
  border: 0px;

}

.table-delete-btn svg {
  font-size: 18px;
  color: #dc3545;
}

.table-view-btn {
  width: 30px;
  height: 30px;
  padding: 0px;
  background-color: rgba(92, 103, 247, 0.2);
  border: 0px;
  border-radius: 5px;
}

.table-edit-btn {
  width: 30px;
  height: 30px;
  padding: 0px;
  background-color: rgba(33, 206, 158, 0.2);
  border: 0px;
  border-radius: 5px;
}


.table-edit-btn svg {
  font-size: 18px;
  color: #21ce9e;
}

.table-view-btn svg {
  font-size: 18px;
  color: var(--secondary);
}

.linked {
  background-color: #0077B5;
}

.twitter {
  background-color: #000;
}

.facebook {
  background-color: #3b5998;
}

.facebook svg,
.twitter svg,
.linked svg {
  color: #fff;
}

.toggle {
  position: relative;
  display: inline-block;
}

.toggle__input {
  display: none;
}

.toggle__label {
  display: block;
  width: 50px;
  height: 25px;
  background-color: var(--secondary);
  border-radius: 99px;
  cursor: pointer;
  transition: background-color all 0.2s ease;
  position: relative;
  /* Add this to position the after pseudo-element correctly */
}

.dark-mode .toggle__label {
  background-color: rgba(92, 103, 247, 0.2);
}

.toggle__label::after {
  content: '';
  position: absolute;
  top: 2.2px;
  right: 26px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: right all 0.2s ease;
}

.toggle01 .toggle__label::after {
  right: 2px !important;
}

.drag-drop-icons {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: rgba(92, 103, 247, 0.1);
}

.drag-over {
  height: 197px;
  width: 200px;
  border: 2px dashed rgb(204, 204, 204);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drag-over p {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  columns: var(--primary);
}

.drag-drop-icons svg {
  font-size: 30px;
}

.image-upload {
  background-color: #fff;
  height: 197px;
  width: 200px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-upload img {
  width: 100% !important;
  height: 100% !important;
}

.team_images .image-upload img {
  width: 100% !important;
  height: 100% !important;
}

/* Testimonials Page End */

/* Loader CSS */
.loader-main {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  z-index: 9;
}

.loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: var(--secondary);
  box-shadow:
    calc(1*var(--d)) calc(0*var(--d)) 0 0,
    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d)) calc(1*var(--d)) 0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d)) calc(-1*var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn)
  }
}

/* Hirings Page Start */
.input-add {
  max-width: 44px;
  min-width: 44px;
  height: 44px;
  background-color: var(--secondary);
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.input-add svg {
  font-size: 20px;
}

.view-image-box {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid var(--primary);
  overflow: hidden;
}

.view-table.table>:not(caption)>*>* {
  border-bottom-width: 0;
}

.responsibilities li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.responsibilities li svg {
  color: #0094EE;
  font-size: 15px;
}

/* Hirings Page End */
/* Pagination Start */
.pagination-main .pagination {
  gap: 10px;
}

.pagination-main .page-item {
  border: 1px solid #dee2e6 !important;
  --bs-border-width: 0px !important;
  width: 40px;
  height: 40px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.pagination-main .page-link {
  border: 0px !important;
  padding: 0px;
  color: var(--primary);
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-main .page-item svg path {
  color: var(--primary);
}

.pagination-main .page-item.active {
  background-color: var(--secondary);
}

.pagination-main .page-item.active span {
  color: #fff !important;
}

.pagination-main .form-select {
  width: 70px;
}

.pagination-main select.form-select {
  padding: 7px 10px;
  color: var(--primary);
  font-weight: 400;
}

/* Pagination End */
/* Details Start */
.details-box label {
  color: #212b37;
  font-size: 15px;
  font-weight: 500;
}

.details-box li,
.details-box p {
  color: #6e829f;
  font-size: 14px;
  font-weight: 400;
}

.no-data-available {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media only screen and (max-width: 991px) and (min-width: 200px) {

  .sidebar-toggel-sm {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 11px;
  }

  .sidebar {
    position: absolute !important;
    z-index: 99;
    max-width: 0px;
  }

  .sidebar-sm {
    max-width: 250px;
  }

  .logo-xl {
    width: 135px;
  }

  .arrow-left {
    font-size: 15px;
  }
}

.width-800 table {
  width: 800px;
}

/* Details End */

.preview-image {
  height: 197px;
  width: 200px;
}

.form-title {
  font-weight: 500;
  font-size: 18px;
  color: var(--secondary);
}

.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #007BFF;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  width: 300px;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
}

.file-input {
  display: none;
  /* Hide the default file input */
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  gap: 20px;
}

.image-preview {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #ddd;
  height: 197px;
  width: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button svg {
  font-size: 20px;
  color: #fff;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  font-weight: 500;
}

.career-flight .career-details {
  border-radius: 10px;
  padding: 20px 20px;
}

.career-flight .career-details .career-icon {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #F9FBFE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-flight .career-details .career-icon img {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.career-flight .career-details h4 {
  color: #132028;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0px;
}

.career-flight .career-details p {
  color: #44464A;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.career-flight .career-details a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--primary);
  padding: 10px 15px;
  display: inline-block;
  border-radius: 5px;
}

#section {
  width: 500px;
  height: 400px;
  word-wrap: break-word;
}

.moretext {
  display: none;
}

.moretext.show {
  display: block;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {

  .preview-image {
    height: 100px;
    width: 100px;
  }

  .image-preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
  }

  .career-flight .career-details {
    padding: 0px;
  }

}

.rating svg {
  width: 25px;
  height: 25px;
}

.rating svg path {
  color: #F8A02D;
}

.sample-screens-image {
  height: auto !important;
}


.latest-innovations .innovations-card {
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.latest-innovations .innovations-card .innovations-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  position: relative;
  z-index: 1;
}

.latest-innovations .innovations-card .innovations-detail h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 5px;
}

.latest-innovations .innovations-card .innovations-detail p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.latest-innovations .innovations-card::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
}

textarea {
  padding: 9px 10px;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  width: 100%;
}

textarea:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--primary);
  outline: 0;
  box-shadow: none !important
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid var(--primary) !important;
}

/* ckEditor css */
.editor-container {
  resize: vertical;
  /* Allow resizing only in the vertical direction */
  overflow: auto;
  /* Enable scrollbars if needed */
  min-width: 300px;
  /* Set a minimum width */
  min-height: 200px;
  /* Set a minimum height */
  max-height: 600px;
  /* Set a maximum height if needed */

  /* Border properties */
  border: 2px solid #007bff;
  /* Change the color and thickness as needed */
  border-radius: 5px;
  /* Optional: add rounded corners */
  padding: 10px;
  /* Optional: add padding inside the border */
  background-color: #f9f9f9;
  /* Optional: set a background color */
}

/* Styling for the Copy Button */
.copy-btn {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  gap: 10px;
  transition: background-color 0.3s ease;
}

/* .copy-btn:hover {
  background-color: #0056b3;
} */

.copy-btn:focus {
  outline: none;
}

/* .copy-btn:active {
  background-color: var(--secondary);
} */

/* Icon Styling */
.copy-icon,
.success-icon {
  font-size: 18px;
}

.success-icon {
  color: #28a745;
}

/* Ensure text stays on one line */
.image-url-text {
  white-space: nowrap;
  /* Ensures the text doesn't wrap to the next line */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Adds '...' if the text is too long */
  max-width: calc(100% - 50px);
  /* Makes sure the text doesn't take up all space and leaves room for the button */
}

.star {
  color: #dc3545;
}

.ck-editor__editable {
  resize: both !important;
  overflow: auto !important;
  width: 100% !important;
}

.ctm-editor .ck-editor__editable {
  min-height: 600px !important;
}


.studies_details_cards .card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 100%;
}

.studies_details_cards .card h4 {
  font-size: 16px;
  font-weight: 500;
  color: #132028;
}

.studies_details_cards .card p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.studies_details_cards .card .process_icons {
  min-width: 60px;
  height: 60px;
  background-color: #F4FBFF;
  border-radius: 50%;
  overflow: hidden;
}

.technologies_name {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px;
  margin-top: 10px;
}

.technologies_name li {
  padding: 5px 10px;
  border: 1px solid var(--secondary);
  border-radius: 5px;
  list-style-type: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.table_content ul {
  margin-bottom: 0px;
}

.table_content ul li {
  padding-bottom: 10px;
  color: #000;
}

.table_content ul li:last-child {
  padding-bottom: 0px;
}

.table_content ul li a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.content_list span {
  margin-block: 10px;
  display: inline-block;
}

.content_list li {
  margin-bottom: 10px;
}